import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { SwUpdate } from '@angular/service-worker';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

export function getBaseUrl()
{
    return document.getElementsByTagName('base')[0].href;
}

const providers = [
    { provide: 'BASE_URL', useFactory: getBaseUrl, deps: [] }
];

if (environment.production)
{
    enableProdMode();
}

platformBrowserDynamic(providers)
	.bootstrapModule(AppModule)
	.then(moduleRef =>
	{
		// check: service worker update
		const swUpdate = moduleRef.injector.get(SwUpdate, null);
		if (swUpdate?.isEnabled)
		{
			swUpdate.checkForUpdate();
		}
	})
	.catch(err => console.log(err));